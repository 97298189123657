import React from 'react'

import { handleNestedObjectKey } from 'utils'
import CoreRenderFunction from 'DisplayCore'

const TechBaseImage = props => {
  let {
    altText,
    src,
    dataKey,
    style,
    className,
    allowPDF
  } = props.item
  const url = ( handleNestedObjectKey( props, dataKey ) || props.data.ClientReducer.clientInfo.logo_image_url )
  if(allowPDF && url.includes('.pdf')) {
    return (
      <CoreRenderFunction 
        {...props} 
        item={{
          type: 'pdf-viewer',
          dataKey: dataKey
        }} />
    )
  } else {
    return(
      <img 
        className={ className || "" }
        style={ style || {} }
        alt={ altText }
        src={ src ? src : ( handleNestedObjectKey( props, dataKey ) || props.data.ClientReducer.clientInfo.logo_image_url ) }
      />
    )
  }
}

export default TechBaseImage